<template>
  <div class="justify-center">

    <v-row class="text-center text-h6 font-weight-bold" justify="center">
      <v-col :class="levelSelected(1)" xl="1">
        <a @click="selectLevel(1)">{{$t('Level')}} 1</a>
      </v-col>
       <v-col :class="levelSelected(2)" xl="1">
        <a @click="selectLevel(2)">{{$t('Level')}} 2</a>
      </v-col>
       <v-col :class="levelSelected(3)" xl="1">
        <a @click="selectLevel(3)">{{$t('Level')}} 3</a>
      </v-col>
    </v-row>
    <v-row justify="center">
           <Matcher :i18n_instructionMsg_key="'matchWordsInstruction'" :matchList="matchList"/>

    </v-row>

  </div>
</template>

<script>
import Matcher from './Matcher'
export default {
  name: 'HelloWorld',
  components: {
    Matcher
  },

  created: function () {
    this.selectLevel(1)
  },
  methods: {
    levelSelected (level) {
      return this.selectedLevel === level ? 'selectedLevel' : 'notSelectedLevel'
    },
    selectLevel (level) {
      this.selectedLevel = level
      this.matchList = Object.keys(this.$store.state.wordList['level' + level])
        .map(k => {
          return {
            guj: k,
            eng: this.$store.state.wordList['level' + level][k].english,
            hindi: this.$store.state.wordList['level' + level][k].hindi
          }
        })
    }
  },
  data: () => ({

    selectedLevel: 1,
    matchList: []
  })
}
</script>
<style>

  .selectedLevel {
    text-decoration: underline;
  }
  .notSelectedLevel {
    text-decoration: none;
  }
</style>
